<template>
  <div>
    <b-button @click="$router.push('/grupos/adicionar')" variant="primary-custom" class="mb-2">Adicionar</b-button>
    <Card>
      <TableList />
    </Card>
  </div>
</template>

<script>


export default {
  components:{
    TableList:()=>import('./components/TableList.vue')
  }
};
</script>